/*jshint latedef: false */
/*************************************************************************************************/
/** JavaScript Bundle - Frontend *****************************************************************/
/*************************************************************************************************/

(function($) {

function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

/*************************************************************************************************/
/** Sage JavaScript Routing **********************************************************************/
/*************************************************************************************************/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
  // All pages
  'common': {
    init: function() {
      // JavaScript to be fired on all pages

      console.log("Frontend gulp JS loader: working");

      // Copy handler for presale codes
      $('.presale-code a').bind('click', function(e) {        
        var $temp = $("<input>");
        $("body").append($temp);
        console.log("Copied presale code", $(this).attr('data-code'));
        $temp.val($(this).attr('data-code')).select();
        document.execCommand("copy");
        $temp.remove();

        $('.presale-code a').html('<i title="Code copied to clipboard" class="fa fa-check"></i>');                
        $('.presale-code span').fadeTo('fast', 0.33);
        e.preventDefault();
      });

      // Force select entire presale code on click
      $('.presale-code span').bind('click', function(e) {        
        var range, selection;
        if (window.getSelection && document.createRange) {
          selection = window.getSelection();
          range = document.createRange();
          range.selectNodeContents(this);
          selection.removeAllRanges();
          selection.addRange(range);
        } else if (document.selection && document.body.createTextRange) {
          range = document.body.createTextRange();
          range.moveToElementText(this);
          range.select();
        }
      });

    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
  // Login page
  'tml_action_login': {
    init: function() {
      // JavaScript to be fired on the login page

      if ($('.tml-error:contains("2FA")').length > 0) {

        // Redirect so WP standard login can handle 2FA logins
        $('.tml-login form').hide();
        console.log("Redirecting to WP standard login to handle 2FA check");
        window.location.href = '/wp-login.php';

      }

      $('.tml-login form button').on('click', function(e) {

        console.log("Forcing form submission");
        $('.tml-login form').unbind();
        $('.tml-login form button').unbind();
        $('.tml-login form').submit();

      });
      $('.tml-login form button').on('submit', function(e) {

        console.log("Forcing form submission");
        $('.tml-login form').unbind();
        $('.tml-login form button').unbind();
        $('.tml-login form').submit();

      });

    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS

      console.log("Login shivs listening...");

    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

// Expose functions
//this.cc__load = cc__load;

})(jQuery); // Fully reference jQuery after this point.